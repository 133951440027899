import React from 'react'
import { Suspense, memo, useEffect, useState } from 'react'
import Logo from '../../assets/glemo-logo.webp'
import { SearchBar } from '../SearchBar/index'
import clsx from 'clsx'
import { useAuth } from '../../hooks/useAuth'
import SkeletonLoading from '../common/SkeletonLoading'
import { ResponseLogin } from '../../store/reducers/interfaces/ResponseLogin'
import { ModalMenuMobile } from '../Modais/ModalMenuMobile'
import UserLoginIcon from '../../assets/icons/blue-user.svg'
import SectionFilterMobile from '../Sections/SectionFilterMobile'

export default memo(HeaderMobile)
function HeaderMobile() {
  const [showSearch, setShowSearch] = useState(location.pathname != '/')
  const [showModalSearch, setShowModalSearch] = useState(false)
  const [showModalMenu, setShowModalMenu] = useState(false)
  const auth = useAuth()
  const [authenticated, setAuthenticated] = useState<boolean>()
  const userLogin = Array.isArray(auth.loginUser) ? auth.loginUser[0] : auth.loginUser

  useEffect(() => {
    setAuthenticated(auth.authenticated)
  }, [auth])

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (!showSearch) {
        let containerAfterSearch = document.querySelector('.container-after-search') as HTMLElement
        if (containerAfterSearch != undefined) {
          let topContainer = containerAfterSearch.getBoundingClientRect().top
          if (window.scrollY >= topContainer) {
            setShowSearch(true)
          } else {
            setShowSearch(false)
          }
        }
      }
    })
  }, [])

  const handleClickBtnModalMobile = async () => {
    setShowModalMenu(true)
  }

  return (
    <>
      <header
        className={clsx('header-mobile bg-white top-0 pt-5 pb-2 fixed z-[99] md:hidden w-[100vw]', {
          'px-4': showModalSearch !== true
        })}
      >
        <div id='mobileContainerSearch'>
          <div className="grid grid-cols-3">
            <div className="m-auto ml-0" onClick={handleClickBtnModalMobile}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                fill="currentColor"
                className="text-glemo-blue"
                viewBox="0 0 256 256"
              >
                <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
              </svg>
            </div>
            <div className="m-auto">
              <a href={'/'}>
                <Suspense fallback={<SkeletonLoading />}>
                  <img alt="Glemo Logo" src={Logo} className="w-full aspect-[191/69]" />
                </Suspense>
              </a>
            </div>
            <div className="m-auto mr-0">
              {authenticated === true ? (
                <>
                  <a
                    href="/perfil/dados-cadastrais"
                    className="border-4 p-2 block text-center rounded-full w-[42px] h-[42px] border-glemo-orange font-bold text-[#4d4d4d] text-base"
                  >
                    <span className="block -mt-1">
                      {(userLogin as ResponseLogin).User?.Claims[3]?.Value[0] ?? ''}
                    </span>
                  </a>
                </>
              ) : (
                <a href="/entrar">
                  <span className="text-blue-700 text-lg font-semibold tracking-wider">
                    <img src={UserLoginIcon} width={20} alt="User Icon" className="m-auto" />
                    <div className="-mt-[6px] text-[14px]">Entrar</div>
                  </span>
                </a>
              )}
            </div>
          </div>
          {showSearch && (
            <div className="mt-5">
              <SearchBar isMobile />
            </div>
          )}
        </div>
        <SectionFilterMobile />
      </header>
      {showModalMenu && (
        <ModalMenuMobile showModal={showModalMenu} closeModal={() => setShowModalMenu(false)} />
      )}
    </>
  )
}
